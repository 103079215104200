<template>
    <div class="rate">
        <h5header :name="$t('market.desc177')" :right="false" />
        <div class="rate-list">
            <div>{{ $t('market.desc178') }}</div>
            <div>{{ $t('market.desc179') }}</div>
        </div>
        <div class="rate-table">
            <div class="table-thead flex">
                <div class="flexcenter">{{ $t('market.desc180') }}</div>
                <div class="flexcenter">{{ $t('market.desc181') }}</div>
                <div class="flexcenter">{{ $t('market.desc182') }}</div>
                <div class="flexcenter">{{ $t('market.desc183') }}</div>
            </div>
            <div class="table-tbody">
                <div class="tbody-info flex" v-for="item in rateList" :key="item.id">
                    <div>{{ item.planetLandSellRatio * 100 }} %</div>
                    <div>{{ item.landReleaseRatio * 100 }} %</div>
                    <div>{{ $t('pool.desc13', { n1: item.releaseDay }) }} </div>
                    <div>{{ item.planetReleaseRatio * 100 }} %</div>
                </div>
            </div>
        </div>
        <div class="ratebtn flexcenter" @click="$router.go(-1)">{{ $t('assetbag.desc71') }}</div>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            rateList: []
        }
    },
    mounted() {
        this.$post(this.URL.planet.config, {}).then(res => {
            if (res.code == 0) {
                this.rateList = res.data;
            }
        })
    }
}
</script>

<style lang="less" scoped>
.rate {
    padding: 60px 16px;
}

.rate-list {
    font-size: 14px;
    color: #FFFFFF;

    div {
        margin-bottom: 16px;
    }
}

.rate-table {
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;

    .table-thead {
        background: rgba(201, 250, 91, 0.10);

        div {
            flex: 0 0 25%;
            padding:12px 6px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.85);
            text-align: center;
            border-right: 1px solid rgba(255, 255, 255, 0.08);

            &:last-child {
                border-right: 0;
            }
        }
    }

    .tbody-info {
        border-top: 1px solid rgba(255, 255, 255, 0.08);

        div {
            flex: 0 0 25%;
            height: 39px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.85);
            text-align: center;
            border-right: 1px solid rgba(255, 255, 255, 0.08);
            line-height: 39px;

            &:last-child {
                border-right: 0;
            }
        }
    }
}

.ratebtn {
    width: 100%;
    height: 44px;
    margin: 24px auto 0;
    border-radius: 6px;
    background: #C9FA5B;
    font-size: 16px;
    color: #282626;
    font-weight: 600;
    cursor: pointer;
}
</style>